import React from 'react';
import "./Skills.css";
import IconReact from "../assets/icons/react.svg";
import IconRuby from "../assets/icons/ruby.svg";
import IconHtml5 from "../assets/icons/html-5.svg";
import IconCss3 from "../assets/icons/css3.svg";
import IconGraphQL from "../assets/icons/graphql.svg";
import IconNodejs from "../assets/icons/nodejs.svg";
import IconPostgresql from "../assets/icons/postgresql.svg";
import IconRails from "../assets/icons/ruby-on-rails.svg";
import IconJavascript from "../assets/icons/javascript-yellow.svg";
import IconTypescript from "../assets/icons/typescript-blue.svg";
import IconBootstrap from "../assets/icons/bootstrap.svg";

function Skills() {
    return (
        <div className="skills-container">
            <div className="skills-block">
                <img className="icon" src={IconJavascript} alt="js"/>
                <h4> Javascript </h4>
            </div>
            <div className="skills-block">
                <img className="icon" src={IconTypescript} alt="ts"/>
                <h4> Typescript </h4>
            </div>
            <div className="skills-block">
                <img className="icon" src={IconReact} alt="react"/>
                <h4>React</h4>
            </div>
            <div className="skills-block">
                <img className="icon" src={IconRails} alt="rails"/>
                <h4> Ruby on Rails </h4>
            </div>
            <div className="skills-block">
                <img className="icon" src={IconNodejs} alt="node"/>
                <h4> Node.js </h4>
            </div>
            <div className="skills-block">
                <img className="icon" src={IconRuby} alt="ruby"/>
                <h4> Ruby </h4>
            </div>
            <div className="skills-block">
                <img className="icon" src={IconHtml5} alt="html"/>
                <h4> HTML </h4>
            </div>
            <div className="skills-block">
                <img className="icon" src={IconCss3} alt="css"/>
                <h4> CSS </h4>
            </div>
            <div className="skills-block">
                <img className="icon" src={IconBootstrap} alt="bootstrap"/>
                <h4> Bootstrap </h4>
            </div>
            <div className="skills-block">
                <img className="icon" src={IconPostgresql} alt="postgres"/>
                <h4> PostgreSQL </h4>
            </div>
            <div className="skills-block">
                <img className="icon" src={IconGraphQL} alt="graphql"/>
                <h4> GraphQL </h4>
            </div>
        </div>
    )
}

export default Skills;
