import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import "./Hello.css";

class Hello extends Component{

    //TODO: use whe contact form is enabled
    // handleGetInTouchClick = () => {
    //     this.props.history.push("/say_hello");
    // }

    render() {
        return (
            <div className="profile-container">
                <div className="profile-wrapper">
                    <div className="bio-intro"> Hi there! I'm <br></br><span className="ts-name"> Taci Shlosberg, </span>
                    <br></br>a Software Engineer based in San Francisco, CA.
                    </div>
                </div>
                <a href="/#contact">
                    <button className="button"> Get in Touch </button>
                    {/* <button className="button" onClick={this.handleGetInTouchClick}> Get in Touch </button> */}
                </a>
            </div>
        )
    }
}
export default withRouter(Hello);