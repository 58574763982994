import React, { Component } from "react";
import Button from "./Button";
import "./ContactPage.css";

class ContactPage extends Component {

    state = {
        name: "",
        email: "",
        message: "",
        sent: null,
    }

    handleChange = (event) => {
        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        })
    }
    
    handleSubmit = async (event) => {
        event.preventDefault();
        
        const { name, email, message } = this.state;
        const contact = {
            name,
            email,
            message
        };

        const response = await fetch("https://taci-portfolio.herokuapp.com/say_hello", {
            // const response = await fetch("/api/form", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(contact)
        });

        this.setState({
            sent: true
        });
        this.resetForm();
    }

    resetForm = () => {
        this.setState({
            name: "",
            email: "",
            message: ""
        });
    }

    render() {
        return (
            <div className="contact-form-container">
                <header> CONTACT </header>
                <div className="contact-form-wrapper"> 
                    <form onSubmit={this.handleSubmit} method="POST">
                        <input 
                            className="input-name"
                            placeholder="NAME"
                            name="name"
                            type="text"
                            value={this.state.name}
                            onChange={this.handleChange}
                            required
                        />
                        <input 
                            className="input-email"
                            placeholder="EMAIL"
                            name="email"
                            type="text"
                            value={this.state.email}
                            onChange={this.handleChange}
                            required
                        />
                        <textarea 
                            className="input-message"
                            placeholder="MESSAGE"
                            name="message"
                            type="textarea"
                            value={this.state.message}
                            onChange={this.handleChange}
                            rows="7"
                            required
                        />
                        <Button className="send-form-button" text="Send" />
                        {this.state.sent === true && <p className="success-msg">Thanks for getting in touch. I'll get back to you soon.</p>}
                        {this.state.sent === false && <p className="error-msg">Message not sent. Please try again.</p>}
                    </form>
                </div> 
            </div>
        )
    }
}

export default ContactPage;