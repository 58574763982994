import React from "react";
import "./About.css";
import photo from "./img/photo.jpg";

function About() {

    return (
        <div>
            <div id="about-me"> </div>
            <p className="about-me-paragraph">
                <img className="about-me-img" src={photo} alt="cartoon-img"></img>
                Hello! I'm Taci, I live in San Francisco but I call many places home.
                <p></p>I'm a recent graduate from a software engineering bootcamp.
                I'm both driven and self motivated, and I'm constantly experimenting with new technologies.
                <p></p> I'm passionate about Web Development, and strive to better myself as a developer. When I'm not keeping busy with coding, you can find me
                lifting heavy things and putting them down or hiking around the bay area.
            </p>
        </div>
    )
}
export default About;