import { FaGithub, FaLinkedinIn, FaMediumM, FaTwitter } from "react-icons/fa";
import "./Social.css";
import { NavLink } from "react-router-dom";


function Social() {

    return (
        <div>
            <div className="social-container">
                <a
                    href="https://github.com/tshlos"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#bf8d7a" }}
                    onMouseOver={({ target }) => target.style.color = "#756254"}
                    onMouseOut={({ target }) => target.style.color = "#bf8d7a"}
                >
                    <FaGithub size={23} className="social-icon github-icon" />
                </a>
                <a
                    href="https://www.linkedin.com/in/taci-shlos/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#bf8d7a" }}
                    onMouseOver={({ target }) => target.style.color = "#756254"}
                    onMouseOut={({ target }) => target.style.color = "#bf8d7a"}
                >
                    <FaLinkedinIn className="social-icon linkedin-icon" />
                </a>
                {/* <a
                    href="https://tshlosberg.medium.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#bf8d7a" }}
                    onMouseOver={({ target }) => target.style.color = "#756254"}
                    onMouseOut={({ target }) => target.style.color = "#bf8d7a"}
                >
                    <FaMediumM className="social-icon medium-icon" />
                </a> */}
                <a
                    href="https://twitter.com/tshloss"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#bf8d7a" }}
                    onMouseOver={({ target }) => target.style.color = "#756254"}
                    onMouseOut={({ target }) => target.style.color = "#bf8d7a"}
                >
                    <FaTwitter className="social-icon twitter-icon" />
                </a>
                <NavLink to="/contact"></NavLink>
            </div>
            <footer class="footer">
                <a class="footer-link" href="https://taci.dev/" target="_self"> Built by Taci Shlosberg </a>
            </footer>
        </div>
    )
}
export default Social;
